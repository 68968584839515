import { windowEvents } from '../library/Constants';
import { emitWindowEvent } from '../utils/emitWindowEvent';
import { utils } from '../utils/Utils';

interface QuestionnaireVariable {
  [key: string]: string;
}

interface VariableDefinition {
  name: string;
  category: 'SYSTEM' | 'CUSTOM';
}

interface Validation {
  isValid: boolean;
  message: string;
}

class QuestionnaireVariableService {
  private state: QuestionnaireVariable;
  private variables: VariableDefinition[];
  private sessionStorage: Storage;
  private api: any;
  private ENV: string;
  private static readonly ENDPOINT_QUESTIONNAIRE_VARIABLES = utils.getAbsoluteResourcePath('questionnaireVariables');
  private static readonly ALLOWED_VALUE_TYPES = ['boolean', 'number', 'string'];
  public static readonly PERSISTED_STORAGE_KEY = 'questionnaireVariablesPersistedState';

  static readonly CACHE_KEY = 'questionnaireVariablesCache';

  constructor(sessionStorage: Storage, api: any, env: string) {
    this.ENV = env;
    this.sessionStorage = sessionStorage;
    this.api = api;
  }

  public async init(): Promise<void> {
    this.variables = await this.getVariables();

    const persistedState = this.sessionStorage.getItem(QuestionnaireVariableService.PERSISTED_STORAGE_KEY) || {};

    const persistedStateObj = this.keepValueAndPreventUnexpectedTokenError(persistedState);
    const validatedPersistedState = this.validatedPersistedState(persistedStateObj);

    this.state = validatedPersistedState;
    this.persistState();

    emitWindowEvent(windowEvents.questionnaireVariables.INITIALISED, this.state);
  }

  private validatedPersistedState(data: { [key: string]: boolean | number | string }): QuestionnaireVariable {
    const validEntries = Object.keys(data).filter((name) => this.isExistingVariable(name));

    const validatedData = validEntries.reduce((result, name) => {
      return { ...result, [name]: data[name].toString() };
    }, {});

    return validatedData;
  }

  private keepValueAndPreventUnexpectedTokenError(data: any): QuestionnaireVariable {
    return JSON.parse(JSON.stringify(data));
  }

  public async getVariables(): Promise<VariableDefinition[]> {
    try {
      return await this.api.get(QuestionnaireVariableService.ENDPOINT_QUESTIONNAIRE_VARIABLES);
    } catch {
      return []; // TODO: Implement proper error handling when adding our endpoint for Questionnaire Variables
    }
  }

  private persistState(): void {
    this.sessionStorage.setItem(QuestionnaireVariableService.PERSISTED_STORAGE_KEY, JSON.stringify(this.state));
  }

  private isExistingVariable(name: string): boolean {
    return this.variables.some((item) => item.name === name);
  }

  private isReserved(name: string): boolean {
    const variableDefinition = this.variables.find((item) => item.name === name);

    return variableDefinition.category === 'SYSTEM';
  }

  public get(name: string): string {
    return this.state[name];
  }

  public getAll(): QuestionnaireVariable {
    return this.state;
  }

  private isValidValueType(value: boolean | number | string): boolean {
    return QuestionnaireVariableService.ALLOWED_VALUE_TYPES.includes(typeof value);
  }

  private logErrorInDevelopment(name: string, message: string): void {
    if (this.ENV !== 'production') {
      console.warn(`[Questionnaire Variables] "${name}" ${message}`);
    }
  }

  public validate(name: string, value: boolean | number | string): Validation {
    const validations = [
      {
        isValid: this.isValidValueType(value),
        message: `${typeof value} is not is not supported`
      },
      {
        isValid: this.isExistingVariable(name),
        message: `is not available`
      }
    ];

    const { isValid = true, message = '' } = validations.find((validation) => !validation.isValid) || {};

    return { isValid, message };
  }

  public set(name: string, value: boolean | number | string): void {
    const { isValid, message } = this.validate(name, value);

    if (!isValid) {
      return this.logErrorInDevelopment(name, message);
    }

    if (this.isReserved(name)) {
      return this.logErrorInDevelopment(name, 'is reserved');
    }

    this.state[name] = value.toString();
    this.persistState();

    emitWindowEvent(windowEvents.questionnaireVariables.UPDATED, this.state);
  }

  public setReserved(name: string, value: boolean | number | string): void {
    const { isValid, message } = this.validate(name, value);

    if (!isValid) {
      return this.logErrorInDevelopment(name, message);
    }

    if (!this.isReserved(name)) {
      return this.logErrorInDevelopment(name, 'is not reserved');
    }

    this.state[name] = value.toString();
    this.persistState();

    emitWindowEvent(windowEvents.questionnaireVariables.UPDATED, this.state);
  }
}

export default QuestionnaireVariableService;
