interface EmitterContext {
  [key: string]: any;
}

interface EmitterEvent {
  context?: any;
  fn: () => void;
}

interface EmitterQueue {
  [key: string]: Array<EmitterEvent>;
}

export default class TinyEmitter {
  public e: EmitterQueue;

  constructor() {
    this.e = {};
  }

  public on(name: string, fn: () => void, context?: EmitterContext): TinyEmitter {
    const eventsQueue: EmitterQueue = this.e;

    (eventsQueue[name] || (eventsQueue[name] = [])).push({ fn, context });

    return this;
  }

  public off(name: string, callback?: () => void): TinyEmitter {
    const eventsQueue: EmitterQueue = this.e;
    const events: Array<EmitterEvent> = eventsQueue[name];
    const liveEvents: Array<EmitterEvent> = [];

    if (events && callback) {
      for (let i = 0, len = events.length; i < len; i++) {
        if (events[i].fn !== callback) {
          liveEvents.push(events[i]);
        }
      }
    }

    if (liveEvents.length) {
      eventsQueue[name] = liveEvents;
    } else {
      delete eventsQueue[name];
    }

    return this;
  }

  public emit(name: string, ...args: any): TinyEmitter {
    const events: Array<EmitterEvent> = (this.e[name] || []).slice();
    const data: any = [].slice.call(args);

    for (let i = 0; i < events.length; i++) {
      events[i].fn.apply(events[i].context, data);
    }

    return this;
  }
}
