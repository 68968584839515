import QuestionnaireVariableService from './service/QuestionnaireVariableService';
import { Options } from './index';
import { IWindow } from './interface/IWindow';
import Api from './library/Api';
import Cache from './library/Cache';
import { local as localStorage, session as sessionStorage } from './library/ClientStorage';
import Cookie, { CookieKey } from './library/Cookie';
import Tracker from './library/Tracker';
import { trackerEventNames } from './library/Constants';
import CampaignService from './service/CampaignService';
import GtmService from './service/GtmService';
import LeadhandlerService from './service/LeadhandlerService';
import MarketingService from './service/MarketingService';
import OptimizelyService from './service/OptimizelyService';
import { utils, generateGUID } from './utils/Utils';
import Session from './valueobject/Session';
import CustomerDataService from './service/CustomerDataService';
import GidService from './service/GidService';
import createPublicLogger from './library/log';

declare let window: IWindow;
declare let __ENV__: string;

function cleanup(): void {
  Cookie.unset(CookieKey.GID);
}

function createSessionLayer(options: Options) {
  if (options.resetData) {
    cleanup();
  }

  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_INIT');

  const session = new Session(sessionStorage, localStorage, options);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_SESSION_INITIALISED');

  const tracker = new Tracker(__ENV__, session);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_TRACKER_INITIALISED');

  const cache = new Cache(localStorage);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_CACHE_INITIALISED');

  const api = new Api(tracker, cache);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_API_INITIALISED');

  const { gtmId, country, locale } = options;

  const campaignService = new CampaignService(api, tracker, options);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_CAMPAIGN_INITIALISED');

  const marketingService = new MarketingService(api, tracker, options, cache);
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_MARKETING_INITIALISED');

  const optimizelyService = new OptimizelyService();
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_OPTIMIZELY_INITIALISED');

  const gtmService = new GtmService(session, marketingService, campaignService, optimizelyService, options);
  const questionnaireVariables = new QuestionnaireVariableService(sessionStorage, api, __ENV__);

  const leadHandler = new LeadhandlerService(
    session,
    marketingService,
    campaignService,
    optimizelyService,
    localStorage,
    sessionStorage,
    options,
    api,
    tracker,
    gtmService
  );
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_LEADHANDLER_INITIALISED');

  const customerDataService = new CustomerDataService(api, locale, questionnaireVariables, session, tracker);
  window.TrackJS && window.TrackJS.console.log('CUSTOMER_DATA_SERVICE_INITIALISED');

  const gidService = new GidService(session, customerDataService, locale, tracker);

  if (!localStorage.isSupported()) {
    tracker.track(trackerEventNames.WEB_STORAGE_NOT_AVAILABLE);
  }

  let testId = localStorage.getItem('testId');

  if (!testId) {
    testId = generateGUID();
    localStorage.setItem('testId', testId); // 7 days
  }
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_BEFORE_GLOBAL_DEFINE');

  return {
    campaignService,
    cookie: Cookie,
    country,
    customerDataService,
    gidService,
    gtm: gtmService,
    gtmId,
    leadHandler,
    locale,
    localStorage,
    log: createPublicLogger(tracker),
    marketingService,
    questionnaireVariables,
    referrer: utils.getReferrer(),
    session,
    sessionStorage,
    testId
  };
}

export default createSessionLayer;
