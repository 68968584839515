import config from '../config/config';
import { domSelector, urlParameter } from '../library/Constants';
import Cookie, { CookieKey } from '../library/Cookie';
import { IWindow } from '../interface/IWindow';
import countryMap from '../valueobject/countryMap';

declare const window: IWindow;

export const generateGUID = (): string => {
  const fourChars = (): string => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  const eightChars = (): string => {
    return fourChars() + fourChars();
  };

  const twelveChars = (): string => {
    return fourChars() + fourChars() + fourChars();
  };

  return `${eightChars()}-${fourChars()}-${fourChars()}-${fourChars()}-${twelveChars()}`;
};

export const safeDecodeURIComponent = (str: string): string => {
  try {
    return decodeURIComponent(str);
  } catch (err) {
    //
  }

  return str;
};

export const getUtmJourney = (locale: string): string => {
  const journey = getUrlParameter(urlParameter.UTM_JOURNEY);
  if (!journey) return null;
  const iso3Country = countryMap[locale];
  if (!iso3Country) return null;
  return `${iso3Country}_${journey.toUpperCase()}_Customer`;
};

export const getUtmCampaign = (): string => {
  const campaign = getUrlParameter(urlParameter.UTM_CAMPAIGN);
  const regex = /[-_]?sd-([a-z]{2})(?:-([a-z]{2}))?[-_]/i;
  const match = campaign.match(regex);
  return match?.[1] && match?.[2] ? `sd-${match[1]}-${match[2]}` : match?.[1] ? `sd-${match[1]}` : null;
};

export const getUrlParameter = (searchParam: string): string => {
  const pageUrl = safeDecodeURIComponent(window.location.search.substr(1));

  if (pageUrl !== '') {
    const urlVariable = pageUrl.split('&');

    for (let i = 0; i < urlVariable.length; i++) {
      const [param, value] = urlVariable[i].split('=');

      if (param.toLowerCase() === searchParam.toLowerCase()) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return value && !utils.isEmpty(value) ? value.trim() : '';
      }
    }
  }

  return '';
};

export const getCookieBannerValue = (gtmId: string, name: string): any => {
  const dataLayer = window.google_tag_manager?.[gtmId]?.dataLayer;

  if (!dataLayer) return null;

  const value = dataLayer.get(name) ?? null;

  return value;
};

export function fetchWithTimeout(url, options, timeout = 5000): Promise<any> {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('Rejected due to timeout')), timeout))
  ]);
}

export function generateGetParameterString(paramsObject: any, startWith = '?'): string {
  let result = '';

  for (const param in paramsObject) {
    if (paramsObject.hasOwnProperty(param) && paramsObject[param]) {
      result += `&${param}=${paramsObject[param]}`;
    }
  }

  if (result !== '') {
    result = startWith + result.substr(1, result.length);
  }

  return result;
}

export function isEmpty(...strings: string[]): boolean {
  for (const str of strings) {
    if (typeof str === 'string' && str.trim() !== '') {
      return false;
    }
  }

  return true;
}

export function ucfirst(str): string {
  str += '';
  const f = str.charAt(0).toUpperCase();
  return f + str.substr(1);
}

export function getMatchingUrlParameters(searchParameter: string): Array<string> {
  const pageUrl = safeDecodeURIComponent(window.location.search.substr(1));

  const matchingParameters: Array<string> = [];

  if (pageUrl !== '') {
    const urlVariable = pageUrl.split('&');
    let parameterName: string[];

    for (let i = 0; i < urlVariable.length; i++) {
      parameterName = urlVariable[i].split('=');

      if (parameterName[0] === searchParameter) {
        matchingParameters.push(parameterName[1]);
      }
    }
  }

  return matchingParameters;
}

export function flatten(arr: Array<any> = []): any {
  const result = {};

  arr.map((item) => {
    Object.keys(item).map((key) => {
      if (item[key] !== '') {
        result[key] = item[key];
      }
    });
  });

  return result;
}

export function generateCacheKey(paramsObject: any): string {
  const cacheKey: Array<string> = [];

  for (const param in paramsObject) {
    if (paramsObject.hasOwnProperty(param)) {
      const value = paramsObject[param];
      if (paramsObject.hasOwnProperty(param) && value) {
        cacheKey.push(value.toLowerCase());
      }
    }
  }

  cacheKey.sort();

  return cacheKey.join('-');
}

export function getOfferType(elem: HTMLElement | null): string {
  if (elem !== null) {
    return elem.getAttribute('data-offer-type') || '';
  }

  return '';
}

export function isValidLocaleString(value: any): boolean {
  return typeof value === 'string' && value.match(/^([a-z]{2}_[A-Z]{2})$/) !== null;
}

export function isset(prop: string, obj: any): boolean {
  return obj && typeof obj === 'object' && obj.hasOwnProperty(prop) && !utils.isEmpty(obj[prop]);
}

export function getLandingUrl() {
  return Cookie.get(CookieKey.LANDING_URL);
}

export function getReferrer(): string {
  let response: any;
  response = Cookie.get(CookieKey.REFERRER);

  if (typeof response !== 'string') {
    response = document.referrer;
  }

  return response.toString();
}

export function getReferrerDomain(): string {
  const referrer = getReferrer();

  if (referrer === '') return '';

  const referrerUrl = new URL(referrer);
  return referrerUrl.hostname;
}

export function getAbsoluteResourcePath(name: string): string {
  return config.resources.absolute[name];
}

export function getRelativeResourcePath(name: string): string {
  return config.resources.relative[name];
}

export function getResourcePath(name: string): string {
  const sessionlayerScriptTag = document.querySelector(domSelector.SESSION_LAYER) as HTMLScriptElement;

  if (!sessionlayerScriptTag || document.location.protocol !== 'https:') {
    return utils.getAbsoluteResourcePath(name);
  }

  const source = sessionlayerScriptTag.src || '';
  const isSameDomain = source.indexOf(document.location.host) !== -1;

  return isSameDomain ? utils.getRelativeResourcePath(name) : utils.getAbsoluteResourcePath(name);
}

export function getLocale(element): string {
  let locale = document.head.getAttribute('data-locale');

  if (utils.isValidLocaleString(locale)) {
    return locale;
  }

  locale = element.getAttribute('data-locale') || '';

  if (utils.isValidLocaleString(locale)) {
    return locale;
  }

  return '';
}

export function getAct(elem: any): string {
  const actUrlParam = getUrlParameter(urlParameter.ACT);
  const actDataAttr = elem.getAttribute('data-act') || '';

  return actDataAttr.toUpperCase() || actUrlParam.toUpperCase() || Cookie.get(CookieKey.ACT);
}

export function getLeadSource(elem: any): string {
  return elem.getAttribute('data-leadsource') === 'optician' ? 'Optician' : 'Web2Lead';
}

export function shouldUpdate(oldParams: any, newParams: any) {
  return !(oldParams && Object.keys(newParams).every((key) => newParams[key] === oldParams[key]));
}

export function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function normalizeBoolean(value: string): boolean {
  return value === 'true' || value === '1';
}

export function booleanToReadable(value: boolean): string {
  return value ? 'Yes' : 'No';
}

function isTimeWithinLimit(time: number, limit: number) {
  const now = Date.now();

  return now - time < limit;
}

function isOldChromeOnWindows10() {
  const MIN_CHROME_VERSION_REQUIRED = 107;

  const isWindows10 = navigator.userAgent.includes('Windows NT 10.0');

  if (!isWindows10) {
    return false;
  }

  const chromeUserAgentParts = /Chrome\/([0-9]+)\./.exec(navigator.userAgent);

  if (!chromeUserAgentParts) {
    return false;
  }

  if (chromeUserAgentParts.length < 2) {
    return false;
  }

  const chromeMajorVersion = parseInt(chromeUserAgentParts[1]);

  if (chromeMajorVersion >= MIN_CHROME_VERSION_REQUIRED) {
    return false;
  }

  return true;
}

function isSalesforceReferrer() {
  const referrer = document.referrer || '';
  return referrer.includes('betterhearing');
}

function isDryRun(): boolean {
  const dryRunParam = getUrlParameter('DryRun');
  return dryRunParam.toLowerCase() === 'true';
}

export const SESSION_LAYER_INIT_SKIP_REASON = {
  TRAFFIC_GENERATED_BY_SALESFORCE: 'Traffic generated by Salesforce',
  NO_USER_AGENT_AVAILABLE: 'No UserAgent available',
  OLD_CHROME_VERSION: 'Old Chrome version running on Windows 10',
  DRY_RUN: 'Dry run mode enabled',
  BOT_USER_AGENT: 'Bot user agent detected'
};

function isBotUserAgent(): boolean {
  const element = document.querySelector(domSelector.SESSION_LAYER);
  if (element == null) {
    return false;
  }
  const locale = utils.getLocale(element);
  if (locale.toLowerCase() !== 'de_de') {
    return false;
  }

  const botUserAgents = [
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.141 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.143 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.78 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.6523.4 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.142 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.6422.114 Safari/537.36',
    'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.6478.36 Safari/537.36'
  ];

  return botUserAgents.includes(navigator.userAgent);
}

export function shouldInitializeSessionLayer(marketingData: any = {}) {
  if (isSalesforceReferrer()) {
    return { result: false, reason: SESSION_LAYER_INIT_SKIP_REASON.TRAFFIC_GENERATED_BY_SALESFORCE };
  }

  if (isDryRun()) {
    return { result: false, reason: SESSION_LAYER_INIT_SKIP_REASON.DRY_RUN };
  }

  if (!navigator.userAgent) {
    return { result: false, reason: SESSION_LAYER_INIT_SKIP_REASON.NO_USER_AGENT_AVAILABLE };
  }

  if (isOldChromeOnWindows10()) {
    return { result: false, reason: SESSION_LAYER_INIT_SKIP_REASON.OLD_CHROME_VERSION };
  }

  if (isBotUserAgent()) {
    return { result: false, reason: SESSION_LAYER_INIT_SKIP_REASON.BOT_USER_AGENT };
  }

  return { result: true };
}

export const utils = {
  booleanToReadable,
  fetchWithTimeout,
  flatten,
  generateCacheKey,
  generateGetParameterString,
  getAbsoluteResourcePath,
  getAct,
  getLandingUrl,
  getLeadSource,
  getLocale,
  getMatchingUrlParameters,
  getOfferType,
  getReferrer,
  getReferrerDomain,
  getRelativeResourcePath,
  getResourcePath,
  isEmpty,
  isset,
  isTimeWithinLimit,
  isValidLocaleString,
  normalizeBoolean,
  shouldInitializeSessionLayer,
  shouldUpdate,
  sleep,
  ucfirst
};
