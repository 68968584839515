import { IWindow } from '../interface/IWindow';
import { utils } from '../utils/Utils';
import Session from '../valueobject/Session';

declare let window: IWindow;

class Tracker {
  public static readonly ENDPOINT_TRACKING = utils.getAbsoluteResourcePath('eventTracker');

  private readonly defaultType = 'SL-lead-monitoring-events';
  private readonly environment: string;
  private session: Session;

  constructor(environment: string, session: Session) {
    this.environment = environment;
    this.session = session;
  }

  public handleError(data: any) {
    if (window.TrackJS) {
      window.TrackJS.console.log(data);
      window.TrackJS.track('Request to event tracker failed');
    }
  }

  public async track(eventName: string, data = {}, type: string = this.defaultType): Promise<Response | void> {
    const overwritable = {
      gid: this.session.getGid()
    };

    const nonOverwritable = {
      createdTime: new Date().toISOString(),
      domain: window.location.host,
      environment: this.environment,
      leadId: this.session.leadId,
      locale: this.session.getLocale(),
      name: eventName,
      path: window.location.pathname,
      referrer: utils.getReferrer(),
      testId: window.sessionLayer && window.sessionLayer.testId,
      type,
      url: this.session.getUrl(),
      userAgent: window.navigator.userAgent
    };

    const payload = {
      ...overwritable,
      ...data,
      ...nonOverwritable
    };

    try {
      const response = await fetch(Tracker.ENDPOINT_TRACKING, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({ event: payload })
      });

      if (response.status < 400) {
        return response;
      }

      this.handleError({
        text: response.statusText,
        code: response.status,
        payload
      });

      return response;
    } catch (error) {
      this.handleError({ error, payload });
    }
  }
}

export default Tracker;
