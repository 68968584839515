import { IWindow } from '../interface/IWindow';
import Tracker from './Tracker';

declare let window: IWindow;

const PUBLIC_LOGGER_TYPE = 'SL-public-logger';

function createPublicLogger(tracker: Tracker): any {
  return (eventName: string, data: any = {}): void => {
    if (typeof data !== 'object') {
      window.TrackJS?.track('Tracker service error');
    }

    tracker.track(eventName, data, PUBLIC_LOGGER_TYPE);
  };
}

export default createPublicLogger;
