export enum CookieLifetime {
  ONE_HOUR = 0.041667
}

export enum CookieKey {
  ACT = 'act',
  FBC = '_fbc',
  FBP = '_fbp',
  GCLID = 'gclid',
  GID = 'LeadGID',
  LANDING_URL = 'landingurl',
  LEAD_DATA = 'LeadData',
  LEAD_ID = 'Lead_ID',
  REFERRER = 'referrer'
}

class Cookie {
  public static get(name: CookieKey): any {
    const cookieItems = document.cookie.split(';');

    for (let i = 0; i < cookieItems.length; i++) {
      const searchKey = `${name}=`;

      cookieItems[i] = cookieItems[i].trim();

      if (cookieItems[i].indexOf(searchKey) !== -1 && searchKey === cookieItems[i].substring(0, searchKey.length)) {
        const cookieValue = cookieItems[i].substring(`${name}=`.length, cookieItems[i].length);
        let result: string;

        try {
          result = JSON.parse(cookieValue);
        } catch (err) {
          result = cookieValue;
        }

        return result;
      }
    }

    return '';
  }

  public static has(name: CookieKey): boolean {
    const cookieItems = document.cookie.split(';');

    let hasName = false;

    for (let i = 0; i < cookieItems.length; i++) {
      cookieItems[i] = cookieItems[i].trim();

      const searchKey = `${name}=`;
      const index = cookieItems[i].indexOf(searchKey);
      const lengthMatches = searchKey === cookieItems[i].substring(0, searchKey.length);

      if (index !== -1 && lengthMatches) {
        hasName = true;
        break;
      }
    }

    return hasName;
  }

  public static set(name: CookieKey, value: string, days?: CookieLifetime): void {
    let expires = '';

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `;expires=${date.toUTCString()}`;
    }
    let domain = Cookie.getDomain();

    if (domain !== '') {
      domain = `domain=.${domain};`;
    }

    document.cookie = `${name}=${value}${expires};${domain}path=/`;
  }

  public static unset(name: CookieKey): void {
    Cookie.set(name, '', -1);
  }

  private static getDomain(): string {
    let domain = document.location.host.split('.').slice(-2).join('.');

    if (domain.indexOf('localhost') > -1) {
      domain = '';
    }

    return domain;
  }
}

export default Cookie;
