/* tslint:disable:variable-name */
import { Options } from '../index';
import { ICampaignData } from '../interface/ICampaignData';
import { IMarketingData } from '../interface/IMarketingData';
import { IWindow } from '../interface/IWindow';
import Api from '../library/Api';
import Cache from '../library/Cache';
import EventEmitter from '../library/EventEmitter';
import Tracker from '../library/Tracker';
import { trackerEventNames, urlParameter } from '../library/Constants';
import { utils, getUrlParameter } from '../utils/Utils';

declare let window: IWindow;

class MarketingService extends EventEmitter {
  public static readonly MAX_RETRY_REQUEST_ATTEMPTS = 3;

  public static REQUEST_ATTEMPT_DELAY = 800;

  public static readonly CACHE_KEY = 'marketing-data';
  public static readonly PARAMS_CACHE_KEY = 'marketing-request-params';

  private readonly options: Options;

  private api: Api;

  private tracker: Tracker;

  private campaignData: ICampaignData;

  private cache: Cache;

  constructor(api: Api, tracker: Tracker, options: Record<string, any> = {}, cache: Cache) {
    super();
    this.api = api;
    this.cache = cache;
    this.tracker = tracker;
    this.options = options;
  }

  public setCampaignData(campaignData) {
    this.campaignData = campaignData;
  }

  private getParams() {
    const { country, act } = this.options;
    return {
      act,
      country,
      referrer: utils.getReferrer()
    };
  }

  public async getInitialOffer(): Promise<string> {
    const getElement = (selector): Promise<HTMLElement | null> =>
      new Promise((resolve) => {
        const state = document.readyState;
        if (state === 'interactive' || state === 'complete') {
          resolve(document.querySelector(selector));
        } else {
          window.addEventListener('DOMContentLoaded', () => {
            resolve(document.querySelector(selector));
          });
        }
      });

    const elem = await getElement('[data-offer-type]');
    return utils.getOfferType(elem);
  }

  public async getData(): Promise<any> {
    const params = this.getParams();
    const endpoint = utils.getResourcePath('marketingService') + utils.generateGetParameterString(params);
    const oldParams = this.cache.get(MarketingService.PARAMS_CACHE_KEY);

    if (utils.shouldUpdate(oldParams, params)) {
      this.cache.clear(MarketingService.CACHE_KEY);
      this.cache.clear(MarketingService.PARAMS_CACHE_KEY);
    }

    const payload = {
      cacheKey: MarketingService.CACHE_KEY,
      paramsCacheKey: MarketingService.PARAMS_CACHE_KEY,
      params: this.getParams(),
      retryAttempts: MarketingService.MAX_RETRY_REQUEST_ATTEMPTS,
      retryDelay: MarketingService.REQUEST_ATTEMPT_DELAY
    };

    try {
      return await this.api.get(endpoint, payload);
    } catch (error) {
      this.tracker.track(trackerEventNames.MARKETING_SERVICE_REQUEST_FAILED, {
        error,
        endpoint,
        payload
      });
      throw new Error(error);
    }
  }

  public getFinalOffer() {
    return getUrlParameter(urlParameter.AUD_P);
  }

  /**
   * TODO: REMOVE AND REFACTOR TO GET THIS DATA WITH OTHER METHOD
   * API method
   * Used in LeadHandler.reactivateLead fn
   */
  public async getLeadData(): Promise<IMarketingData> {
    const initialOffer = await this.getInitialOffer();
    const finalMarketingOffer = this.getFinalOffer() || initialOffer;

    return new Promise(async (resolve) => {
      try {
        const marketingData = await this.getData();
        const {
          act: act__c,
          partner: Marketing_Partner__c,
          channel: Controlling_Channel__c,
          usage: Usage__c,
          offer
        } = this.campaignData;

        resolve({
          act__c,
          Marketing_Partner__c,
          Controlling_Channel__c,
          Usage__c,
          Initial_Offer__c: offer,
          Marketing_Offer_V2__c: finalMarketingOffer || offer,
          IP_Address__c: marketingData.browser.ip,
          Browser__c: [
            marketingData.browser.version,
            marketingData.browser.name,
            marketingData.os.name,
            marketingData.os.version
          ].join(' | '),
          User_Device__c: utils.ucfirst(marketingData.browser.device)
        });
      } catch (e) {
        resolve({
          Initial_Offer__c: initialOffer,
          Marketing_Offer_V2__c: finalMarketingOffer
        });
      }
    });
  }
}

export default MarketingService;
