const twelveHours = 12 * 60 * 60 * 1000;

const config = {
  resources: {
    absolute: {
      campaignService: 'https://cma.cdn.audibene.net/campaign',
      marketingService: 'https://marketing.audibene.net/session',
      leadHandler: 'https://leadhandler.audibene.net/lead/',
      reactivation: 'https://leadhandler.audibene.net/crm/reactivation',
      eventTracker: 'https://et.cdn.audibene.net/services/collector/event',
      questionnaireVariables: 'https://formservice.cdn.audibene.net/variables/minimal',
      customerDataService: 'https://customer.audibene.net'
    },
    relative: {
      customerDataService: '/customer-data-service'
    }
  },
  timeout: {
    marketingService: 10000,
    leadHandler: 2000,
    leadHandlerRead: 500
  },
  validityTime: {
    reactivationGid: twelveHours
  },
  endpoint: {
    leadHandler: '/short'
  }
};

config.resources.relative = {
  ...config.resources.absolute,
  ...config.resources.relative
};

export default config;
