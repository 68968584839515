class Cache {
  private storage: any;

  constructor(storage: any) {
    this.storage = storage;
  }

  public clear(cacheKey: string) {
    this.storage.removeItem(cacheKey);
  }

  public set(cacheKey: string, value: string) {
    this.storage.setItem(cacheKey, value);
  }

  public get(cacheKey: string) {
    return this.storage.getItem(cacheKey);
  }
}

export default Cache;
