import './library/md5';
import { domSelector, questionnaireVariables, urlParameter, windowEvents } from './library/Constants';
import { getReferrer, getUrlParameter, shouldInitializeSessionLayer, utils } from './utils/Utils';
import createSessionLayer from './SessionLayer';
import { IWindow } from './interface/IWindow';
import { emitWindowEvent } from './utils/emitWindowEvent';
import { default as parsePhoneNumberFromString } from 'libphonenumber-js/max';

declare let window: IWindow;

window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_START');

export type Options = {
  resetData?: boolean;
  locale?: string;
  gtmId?: string;
  act?: string;
  country?: string;
  leadSource?: string;
  visitorId?: string;
  upsert?: string;
};

(async function (window, document) {
  const element = document.querySelector(domSelector.SESSION_LAYER);
  const locale = utils.getLocale(element);

  const options: Options = {
    locale,
    resetData: utils.normalizeBoolean(element.getAttribute('data-resetdata')),
    country: locale && locale.slice(3),
    gtmId: element.getAttribute('data-gtmid') || '',
    act: utils.getAct(element),
    leadSource: utils.getLeadSource(element),
    upsert: getUrlParameter(urlParameter.UPSERT),
    visitorId: getUrlParameter(urlParameter.VISITOR_ID)
  };

  const sessionLayer = createSessionLayer(options);
  const shouldInit = shouldInitializeSessionLayer();

  if (!shouldInit.result) {
    sessionLayer.log('SESSIONLAYER_INIT_SKIPPED', {
      referrer: getReferrer(),
      userAgent: navigator.userAgent,
      reason: shouldInit.reason
    });

    return;
  }

  window.sessionLayer = sessionLayer;
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_GLOBAL_DEFINED');

  if (sessionLayer.session.isReactivation()) {
    const { gid, timestamp } = await sessionLayer.gidService.selectGid();
    sessionLayer.gidService.activateGid(gid, timestamp);
  }
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_GID_INITIALISED', sessionLayer.session.getGid());

  sessionLayer.leadHandler.attachOptimizelyInfo();
  await sessionLayer.leadHandler.attachMarketingInfo();

  const [campaignData, marketingData] = await Promise.all([
    sessionLayer.campaignService.getData(),
    sessionLayer.marketingService.getData()
  ]);
  sessionLayer.marketingService.setCampaignData(campaignData);

  emitWindowEvent(windowEvents.sessionLayer.REACTIVATION_READY, null);

  await sessionLayer.gtm.init();
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_GTM_INITIALISED');

  await sessionLayer.questionnaireVariables.init();
  window.TrackJS && window.TrackJS.console.log('SESSIONLAYER_QUESTIONNAIRE_VARIABLES_INITIALISED');

  const previousLeadGid = sessionLayer.session.getGidFromLocalStorage();

  if (previousLeadGid) {
    const previousLeadStatus = await sessionLayer.leadHandler.getPreviousLeadStatus();
    const previousLeadTimestamp = sessionLayer.session.getLeadCreationTimeFromLocalStorage();

    const previousLeadStatusEvent = new CustomEvent('previousLeadStatus', { detail: previousLeadStatus });

    window.dispatchEvent(previousLeadStatusEvent);

    sessionLayer.gtm.push({
      event: 'previousLead',
      previousLeadGid,
      previousLeadStatus,
      previousLeadTimestamp
    });
  }

  sessionLayer.questionnaireVariables.setReserved(
    questionnaireVariables.USER_RETURNING_LEAD,
    utils.booleanToReadable(Boolean(previousLeadGid))
  );

  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_ACT, campaignData?.act);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_BRAND, campaignData?.brand);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_OFFER, campaignData?.offer);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_PARTNER, campaignData?.partner);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_USAGE, campaignData?.usage);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.MARKETING_CHANNEL, campaignData?.channel);

  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.DEVICE_TYPE, marketingData?.browser?.device);
  sessionLayer.questionnaireVariables.setReserved(questionnaireVariables.DEVICE_OS, marketingData?.os?.name);

  sessionLayer.questionnaireVariables.setReserved(
    questionnaireVariables.USER_RETURNING_VISITOR,
    utils.booleanToReadable(sessionLayer.session.isReturningVisitor())
  );

  sessionLayer.session.setVisitorSession();

  appendPhoneValidationLibraryToWindow();

  emitWindowEvent(windowEvents.sessionLayer.READY, null);
})(window, document);

export function appendPhoneValidationLibraryToWindow(): void {
  const libphonenumber = { parsePhoneNumberFromString };
  window.libphonenumber = libphonenumber;
}
