import { Options } from '../index';
import { ILeadData } from '../interface/ILeadData';
import { urlParameter, cookieBannerKey } from '../library/Constants';
import Cookie, { CookieKey } from '../library/Cookie';
import { utils, getUrlParameter, getCookieBannerValue, getUtmJourney, getUtmCampaign } from '../utils/Utils';

/**
 * TODO
 * this is not a model anymore. It has a lot stuff inside that managed application state
 * better call it service (and move it) or inject data on creation
 */
class Lead {
  protected lead: ILeadData;

  constructor(gid: string, leadId: string, visitorId: string, options: Options) {
    // in case url contains LeadCustomerGID=...&upsert=no
    // take given gid and send it in field original_gid as well as upsert param to be
    const { leadSource, locale, country = '' } = options;
    const isUS = country.toLowerCase() === 'us';
    const upsert = getUrlParameter(urlParameter.UPSERT);
    const originalGid = getUrlParameter(urlParameter.LEAD_GID);
    const utmTerm = getUrlParameter(urlParameter.UTM_TERM);
    const jobId = getUrlParameter(urlParameter.SFMC_JOBID);
    const jsbId = getUrlParameter(urlParameter.SFMC_JSBID);
    const subId = getUrlParameter(urlParameter.SFMC_SUBID);
    const sfmcSubscriberKey = getUrlParameter(urlParameter.SFMC_SUBSCRIBER_KEY);
    const recommendedBy = getUrlParameter(urlParameter.RECOMMENDED_BY);
    const tbclid = getUrlParameter(urlParameter.TBCLID);
    const state = getUrlParameter(urlParameter.STATE);
    const referrer = this.getCookieData(CookieKey.REFERRER);
    const landingUrl = this.getCookieData(CookieKey.LANDING_URL);
    const variationTrackingParams = [
      ...utils.getMatchingUrlParameters(urlParameter.TEST), // TODO remove, after the new A/B test solution was rolled out globally
      ...utils.getMatchingUrlParameters(urlParameter.VARIATION_ID)
    ];

    this.lead = {
      GID__c: gid,
      Lead_ID__c: leadId,
      Visitor_Id__c: visitorId,
      Locale__c: locale,
      GCLID__c: getUrlParameter(urlParameter.GCLID),
      Ad_Id__c: getUrlParameter(urlParameter.UTM_CONTENT),
      Publisher_ID__c: getUrlParameter(urlParameter.UTM_PUBLISHER_ID),
      Section_ID__c: getUrlParameter(urlParameter.UTM_SECTION_ID),
      MKT_Tool_Id__c: getUrlParameter(urlParameter.MKT_TOOL_ID),
      Referrer__c: referrer,
      Referrer_GID__c: getUrlParameter(urlParameter.REFERRER_GID),
      Landing_URL__c: landingUrl,
      OM_Test_ID__c: variationTrackingParams.join(','),
      Customer_browser_language__c: window.navigator.language || (window.navigator as any).userLanguage,
      LeadSource: leadSource,
      UserAgent__c: window.navigator.userAgent,
      fbc: this.getCookieData(CookieKey.FBC),
      fbclid: getUrlParameter(urlParameter.FBCLID),
      fbp: this.getCookieData(CookieKey.FBP),
      Selected_Journey__m: getUtmJourney(locale),
      Selected_Campaign__m: getUtmCampaign()
    };

    if (upsert === 'no' && originalGid) {
      this.extend({
        original_gid: originalGid,
        upsert,
        // additional Marketing Information Object fields if GID and upsert parameters are in URL (WS-2180)
        marketingInformation: {
          GidCreatedFor__c: this.lead.GID__c,
          GidEmail__c: originalGid
        }
      });
    }

    if (state && !isUS) {
      this.add('State', state);
    }

    if (recommendedBy) {
      this.add('Recommended_by__c', recommendedBy);
    }

    if (utmTerm) {
      this.add('utm_term__c', utmTerm);
    }

    if (tbclid) {
      this.add('s2stracking__c', tbclid);
    }

    if (originalGid && (sfmcSubscriberKey || jobId || jsbId || subId)) {
      this.add('marketingInformation', {
        LeadCustomerGID: originalGid,
        Locale__c: locale,
        sfmc_subscriberkey: sfmcSubscriberKey,
        sfmc_jobid: jobId,
        sfmc_jsbid: jsbId,
        sfmc_subid: subId,
        ...this.lead.marketingInformation
      });
    }
  }

  // TODO: change leadKeys to enum and use here instead of a string
  public has(name: string) {
    return this.lead.hasOwnProperty(name);
  }

  private getCookieData(key: CookieKey) {
    return Cookie.get(key);
  }

  public add(name: string, value: any) {
    this.lead[name] = value;
    return this;
  }

  public get(name: string): any {
    return this.has(name) ? this.lead[name] : null;
  }

  /**
   * dmc = dynamic marketing comment
   * This field is used to freeze the lead data in Salesforce.
   * We will always have the initial user's data even if someone updates the rest of the fields in SF directly
   */
  public updateDmcField() {
    delete this.lead.Dynamic_Marketing_Comment__c;
    this.add('Dynamic_Marketing_Comment__c', JSON.stringify(this.lead));
    return this;
  }

  public updateGid(gid: string): void {
    this.add('GID__c', gid);
    this.add('marketingInformation', {
      ...this.lead.marketingInformation,
      GidCreatedFor__c: gid
    });
  }

  public extend(data: any) {
    this.lead = {
      ...this.lead,
      ...data
    };

    return this;
  }

  public hasPhone(): boolean {
    const data = this.getData();
    return utils.isset('Phone', data) || utils.isset('MobilePhone', data);
  }

  /**
   * Checks on Phone or Email
   */
  public hasRequiredFields(): boolean {
    const hasPhone = this.hasPhone();
    const hasEmail = utils.isset('Email', this.lead);

    return hasPhone || hasEmail;
  }

  public getData() {
    return this.lead;
  }

  /**
   * Checks on Email and NO Phone or MobilePhone
   */
  public isEmailOnlyLead(): boolean {
    const data = this.getData();
    const hasPhone = this.hasPhone();
    const hasEmail = utils.isset('Email', data);

    return hasEmail && !hasPhone;
  }

  public addMarketingSalesTrackingValue(gtmId: string) {
    const isExplicitConsent = getCookieBannerValue(gtmId, cookieBannerKey.TYPE) === 'explicit';
    const marketingSalesTracking = getCookieBannerValue(gtmId, cookieBannerKey.MARKETING_SALES_TRACKING);
    const value = isExplicitConsent ? marketingSalesTracking : null;

    this.add('Marketing_Sales_Tracking__c', value);

    return this;
  }
}

export default Lead;
