export interface ILeadData {
  Ad_Id__c: string;
  Customer_browser_language__c: string;
  Dynamic_Marketing_Comment__c?: any;
  Email?: string;
  fbc?: string;
  fbclid?: string;
  fbp?: string;
  GCLID__c: string;
  GID__c: string;
  Landing_URL__c: string;
  LastName?: string;
  Lead_ID__c: string;
  LeadSource?: string;
  Locale__c: string;
  Marketing_Sales_Tracking__c?: boolean;
  marketingInformation?: any;
  MKT_Tool_Id__c?: string;
  MobilePhone?: string;
  OM_Test_ID__c: string;
  Phone?: string;
  Publisher_ID__c: string;
  Referrer__c: string;
  Referrer_GID__c?: string;
  Section_ID__c: string;
  Selected_Journey__m?: string;
  Selected_Campaign__m?: string;
  Status?: string;
  UserAgent__c?: string;
  Visitor_Id__c: string;
}

export enum LeadSourceOptions {
  'OneClick' = 'OneClick',
  'Optician' = 'Optician',
  'Other' = 'Other',
  'Web2Lead' = 'Web2Lead'
}
