import { Options } from '../index';
import { ICampaignData } from '../interface/ICampaignData';
import { urlParameter } from '../library/Constants';
import Cookie, { CookieKey } from '../library/Cookie';
import { utils } from '../utils/Utils';
import Session from '../valueobject/Session';
import CampaignService from './CampaignService';
import MarketingService from './MarketingService';
import OptimizelyService from './OptimizelyService';
import { IMarketingData } from '../interface/IMarketingData';

declare let window: any;

const LAYER_NAME = 'dataLayer';

class GtmService {
  private session: Session;

  private readonly options: Options;

  private campaignService: CampaignService;

  private marketingService: MarketingService;

  private optimizelyService: OptimizelyService;

  constructor(
    session: Session,
    marketingService: MarketingService,
    campaignService: CampaignService,
    optimizelyService: OptimizelyService,
    options: Options
  ) {
    this.session = session;
    this.marketingService = marketingService;
    this.campaignService = campaignService;
    this.optimizelyService = optimizelyService;
    this.options = options;
  }

  public async init(): Promise<any> {
    const { gtmId } = this.options;

    let marketingData: IMarketingData;
    let campaignData: ICampaignData;

    try {
      marketingData = await this.marketingService.getData();
    } catch {
      window.TrackJS && window.TrackJS.console.log('SL:[GtmService] Marketing data request failed');
    }

    try {
      campaignData = await this.campaignService.getData();
    } catch {
      window.TrackJS && window.TrackJS.console.log('SL:[GtmService] Campaign data request failed');
    }

    const data = this.prepareDataForGTM(marketingData, campaignData);

    this.unshift(data);

    function gtag(consent: string, behavior: string, data: any) {
      window[LAYER_NAME].push(arguments);
    }

    if (gtmId) {
      const locale = this.options.locale?.toLocaleLowerCase();
      // GDPR = General Data Protection Regulation
      const gdprLocales = ['de_de', 'nl_nl', 'fr_fr', 'de_ch'];
      const isCountryWithGDPR = gdprLocales.includes(locale);

      if (isCountryWithGDPR) {
        gtag('consent', 'default', {
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          ad_storage: 'denied',
          analytics_storage: 'denied',
          wait_for_update: 2000
        });
      }

      this.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
    }

    this.placeScriptTag();
  }

  /**
   * https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/type
   * @param device
   */
  private getNetworkType(device = ''): string {
    const isMobile = device.toLowerCase() === 'mobile';

    const connection =
      window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection || {};
    const { type = null } = connection;

    return isMobile ? type : null;
  }

  private prepareDataForGTM(marketingData: IMarketingData, campaign: ICampaignData = {}) {
    const { locale } = this.options;
    const device = marketingData?.browser?.device;

    const gtmData = {
      page: {
        referrer: Cookie.get(CookieKey.REFERRER),
        url: this.session.getUrl(),
        varid: [
          ...utils.getMatchingUrlParameters(urlParameter.VARIATION_ID),
          ...this.optimizelyService.getVariationIds()
        ].join(',')
      },
      user: {
        gid: this.session.getGid(),
        leadId: this.session.getLeadId(),
        visitorId: this.session.getVisitorId()
      },
      networkType: this.getNetworkType(device),
      ...(marketingData || {}),
      campaign
    };

    // TODO: do we need overwriting the locale from marketing?
    gtmData.site = {
      ...((marketingData && marketingData.site) || {}),
      locale
    };

    return gtmData;
  }

  public placeScriptTag(): void {
    const { gtmId } = this.options;

    if (gtmId) {
      const firstScript = document.querySelector('script');
      const script: HTMLScriptElement = document.createElement('script');

      script.async = true;
      script.setAttribute('data-usercentrics', 'Google Tag Manager');
      script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

      // element can be null
      if (firstScript.parentNode) {
        firstScript.parentNode.insertBefore(script, firstScript);
      }
    }
  }

  public unshift(payload: any): void {
    window[LAYER_NAME].unshift(payload);
  }

  private process(payload: any): void {
    const { data } = payload.questionnaire || {};

    if (data && data.Email) {
      data.emailHashed = window.md5(data.Email.trim());
    }

    return payload;
  }

  public push(payload: any): void {
    if (!payload) {
      return;
    }

    const data = this.process(payload);
    window[LAYER_NAME].push(data);
  }
}

window[LAYER_NAME] = window[LAYER_NAME] || [];

export default GtmService;
