import Session from 'valueobject/Session';
import Tracker from '../library/Tracker';
import CustomerDataService, { CustomerProfileData } from './CustomerDataService';
import { trackerEventNames } from '../library/Constants';

interface IGidUsability {
  gid: string;
  timestamp: number;
  usable: boolean;
}

class GidService {
  private session: Session;
  private customerDataService: CustomerDataService;
  private locale: string;
  private tracker: Tracker;

  constructor(session: Session, customerDataService: CustomerDataService, locale: string, tracker: Tracker) {
    this.session = session;
    this.customerDataService = customerDataService;
    this.locale = locale;
    this.tracker = tracker;
  }

  public async selectGid(): Promise<IGidUsability> {
    if (this.session.isReactivation()) {
      return await this.selectReactivationGid();
    }

    return Promise.resolve({
      gid: this.session.selectGID(),
      timestamp: Date.now(),
      usable: true
    });
  }

  public activateGid(gid: string, timestamp: number): void {
    this.session.activateGid(gid, timestamp);
  }

  private async getCustomerProfileData(): Promise<CustomerProfileData> {
    const customerProfileGid = this.session.getCustomerProfileGidFromUrl();
    const previousLeadGidFromUrl = this.session.getPreviousGidFromUrl();

    const isCustomerProfileGid = this.session.isValidGid(customerProfileGid);

    if (isCustomerProfileGid) {
      const customerData: CustomerProfileData = await this.customerDataService.fetchCustomerProfileByGid(
        customerProfileGid
      );

      if (customerData.customer_gid) {
        return customerData;
      }
    }

    const customerData: CustomerProfileData = await this.customerDataService.fetchCustomerProfileByRelationGid(
      previousLeadGidFromUrl
    );

    return customerData;
  }

  private async selectReactivationGid(): Promise<IGidUsability> {
    const method = 'selectReactivationGid';
    try {
      let gidFromLocalStorage: IGidUsability = this.session.getReactivationGidFromLocalStorage();
      if (gidFromLocalStorage.usable) {
        return gidFromLocalStorage;
      }

      const currentSessionGidFromUrl = this.session.getSessionGidFromUrl();
      if (currentSessionGidFromUrl) {
        this.tracker.track(trackerEventNames.REACTIVATION_LOCALSTORAGE_FAILED, {
          error: gidFromLocalStorage,
          locale: this.locale,
          gid: currentSessionGidFromUrl,
          method
        });

        return Promise.resolve({
          gid: currentSessionGidFromUrl,
          timestamp: Date.now(),
          usable: true
        });
      }

      const customerData: CustomerProfileData = await this.getCustomerProfileData();
      const lastLeadCreatedAt: number = Date.parse(customerData.last_lead_created_at);
      const lastLeadGid = customerData.last_gid;
      this.session.setLastLeadGid(lastLeadGid);
      this.session.setLastLeadCreatedAt(lastLeadCreatedAt);

      const gidFromCps: IGidUsability = this.session.getReactivationGidFromCps();

      // After getting the GID from CPS, we need to check localStorage once again
      // just in case a concurrent tab has stored a new GID in the mean time.
      gidFromLocalStorage = this.session.getReactivationGidFromLocalStorage();

      if (gidFromLocalStorage.usable) {
        return gidFromLocalStorage;
      }

      if (gidFromCps.usable) {
        return gidFromCps;
      }
    } catch (err) {
      this.tracker.track(trackerEventNames.REACTIVATION_GID_FAILED, {
        error: JSON.stringify(err),
        locale: this.locale,
        method
      });
    }

    const generatedGid = this.session.createReactivationGid();

    return Promise.resolve({
      gid: generatedGid,
      timestamp: Date.now(),
      usable: true
    });
  }
}

export default GidService;
