import Cookie, { CookieKey, CookieLifetime } from '../library/Cookie';
import { utils } from '../utils/Utils';

type Items = { [key: string]: Array<any> };
type QuestionnaireAnswer = { [key: string]: string };

class Buffer {
  private items: Items = {};

  public add(id: string, data: any) {
    if (!this.items[id]) {
      this.items[id] = [];
    }

    this.items[id].push(data);
    this.setCookie(id);
  }

  private reducer(obj: QuestionnaireAnswer, item: QuestionnaireAnswer): QuestionnaireAnswer {
    const properties = ['Email', 'MobilePhone', 'Phone', 'PostalCode'];
    const hasAtLeastOneProperty = properties.some((property) => item.hasOwnProperty(property));

    return hasAtLeastOneProperty ? { ...obj, ...item } : obj;
  }

  private setCookie(id: string): void {
    const bufferItem = this.items[id];
    const values = bufferItem.reduce(this.reducer, Cookie.get(CookieKey.LEAD_DATA) || {});

    const { Email, MobilePhone, Phone, PostalCode } = values;
    const noValues = ![Email, MobilePhone, Phone, PostalCode].some(Boolean);

    if (noValues) return;

    const data = {
      EmailProvided: !!Email,
      MobilePhone,
      Phone,
      PostalCode
    };

    Cookie.set(CookieKey.LEAD_DATA, JSON.stringify(data), CookieLifetime.ONE_HOUR);
  }

  public removeLast(id: string) {
    const items = this.items[id] || [];

    if (items.length > 0) {
      items.pop();
    }
  }

  public flatten(id: string): any {
    return utils.flatten(this.items[id]);
  }

  public getData(): Items {
    return this.items;
  }
}

export default Buffer;
