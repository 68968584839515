import { IOptimizelyData } from 'interface/IOptimizelyData';
import { IWindow } from 'interface/IWindow';

declare let window: IWindow;

class OptimizelyService {
  private optimizelyData: any = {};

  private optimizelyState: any = {};

  private optimizelyVisitor: any = {};

  constructor() {
    this.init();
  }

  private init() {
    if (window.optimizely && window.optimizely.initialized) {
      this.optimizelyData = window.optimizely.get('data');
      this.optimizelyState = window.optimizely.get('state');
      this.optimizelyVisitor = window.optimizely.get('visitor');
    }
  }

  private getProjectId(): string {
    const { projectId = '' } = this.optimizelyData;
    return projectId;
  }

  private getVisitorId(): string {
    const { visitorId = '' } = this.optimizelyVisitor;
    return visitorId;
  }

  private getVariationMap(): any[] {
    return this.optimizelyState.getVariationMap ? this.optimizelyState.getVariationMap() : [];
  }

  public getVariationIds(): string[] {
    const variationsMap = this.getVariationMap();

    return this.getActiveExperiments(variationsMap).map((key) => variationsMap[key].id);
  }

  private getActiveExperiments(variationsMap = {}): any[] {
    // NOTE: Active experiment means "index" is not null
    return Object.keys(variationsMap).filter((key) => variationsMap[key].index !== null);
  }

  public getData(): IOptimizelyData {
    const projectId: string = this.getProjectId();
    const visitorId: string = this.getVisitorId();

    const experimentIdsList = [];
    const variationIdsList = [];

    const variationsMap = this.getVariationMap();
    this.getActiveExperiments(variationsMap).map((key) => {
      experimentIdsList.push(key);
      variationIdsList.push(variationsMap[key].id);
    });

    const experimentIds = experimentIdsList.join(',');
    const variationIds = variationIdsList.join(',');

    return {
      Test_Variation_Experiment_Id__c: experimentIds,
      Test_Variation_Experiment_Variation__c: variationIds,
      Test_Variation_Project_Id__c: projectId,
      visitor_id_optimizely__c: visitorId
    };
  }
}

export default OptimizelyService;
