export default {
  af_ZA: 'ZAF',
  bn_IN: 'IND',
  de_CH: 'CHE',
  de_DE: 'DEU',
  en_CA: 'CAN',
  en_GB: 'GBR',
  en_HK: 'MYS',
  en_IN: 'IND',
  en_MY: 'MYS',
  en_SG: 'MYS',
  en_TH: 'THA',
  en_US: 'USA',
  en_ZA: 'ZAF',
  es_US: 'USA',
  fr_CA: 'CAN',
  fr_CH: 'CHF',
  fr_FR: 'FRA',
  fr_LU: 'LUX',
  gu_IN: 'IND',
  hi_IN: 'INH',
  ja_JP: 'JPN',
  kn_IN: 'IND',
  ko_KR: 'KOR',
  ml_IN: 'IND',
  mr_IN: 'IND',
  ms_MY: 'MYM',
  nl_NL: 'NLD',
  pa_IN: 'IND',
  ta_IN: 'IND',
  te_IN: 'IND',
  th_TH: 'THA',
  zh_CN: 'CNZ',
  zh_HK: 'MYS',
  zh_MY: 'MYZ',
  zh_SG: 'MYS',
  zh_TW: 'TWN'
};
