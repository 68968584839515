import config from '../config/config';
import { Options } from '../index';
import { localStorageKey, sessionStorageKey, urlParameter } from '../library/Constants';
import Cookie, { CookieKey } from '../library/Cookie';
import Lead from '../models/Lead';
import { generateGUID, getUrlParameter, utils } from '../utils/Utils';
import countryMap from './countryMap';

export const GID_PREFIX = '001';
export const GID_REACTIVATION_PREFIX = '888';

class Session {
  private sessionStorage: any;
  private localStorage: any;
  public leadId: string;
  public visitorId: string;
  public gid: string;
  public options: Options;
  public lead: Lead;
  private locale: string;
  private visitorTimestamp: string;
  private reactivationGidValidity: number;
  private lastLeadGid: string;
  private lastLeadCreatedAt: number;

  private nowTimestamp: number = new Date().getTime();

  constructor(sessionStorage: any, localStorage: any, options: Options) {
    this.reactivationGidValidity = config.validityTime.reactivationGid;

    const { locale, visitorId } = options;

    this.options = options;
    this.sessionStorage = sessionStorage;
    this.localStorage = localStorage;
    this.locale = locale;

    this.setInitialCookie();

    this.leadId = this.getLeadIdFromCookie() || this.createLeadIdFromLocale(this.locale);
    this.visitorId = visitorId || generateGUID();

    if (!this.isReactivation()) {
      this.gid = this.selectGID();
      this.sessionStorage.setItem(sessionStorageKey.SESSION_GID, this.gid);
    }

    this.lead = new Lead(this.gid, this.leadId, this.visitorId, this.options);

    this.visitorTimestamp = this.localStorage.getItem(localStorageKey.VISITOR_TIMESTAMP);
    this.setVisitor();
  }

  public activateGid(gid: string, timestamp: number): void {
    this.gid = gid;

    if (this.isReactivation()) {
      this.localStorage.setItem(localStorageKey.REACTIVATION_GID, this.gid);
      this.localStorage.setItem(localStorageKey.REACTIVATION_GID_GENERATED_AT, timestamp.toString());
    } else {
      this.sessionStorage.setItem(sessionStorageKey.SESSION_GID, this.gid);
    }

    this.lead.updateGid(gid);
  }

  private setVisitor(): void {
    this.localStorage.setItem(localStorageKey.VISITOR_TIMESTAMP, this.nowTimestamp.toString());
  }

  public setVisitorSession(): void {
    this.sessionStorage.setItem(sessionStorageKey.VISITOR_TIMESTAMP, this.nowTimestamp.toString());
  }

  public isReturningVisitor(): boolean {
    const isSameVisitorSession = !!this.sessionStorage.getItem(sessionStorageKey.VISITOR_TIMESTAMP);
    return !!this.visitorTimestamp && !isSameVisitorSession;
  }

  public getLeadCreationTimeFromLocalStorage(): string {
    return this.localStorage.getItem(localStorageKey.LEAD_CREATION_TIME);
  }

  public getGidFromLocalStorage(): string {
    return this.localStorage.getItem(localStorageKey.GID);
  }

  public getLeadIdFromLocalStorage(): string {
    return this.localStorage.getItem(localStorageKey.LEAD_ID);
  }

  public isReactivation(): boolean {
    const { upsert } = this.options;
    return upsert === 'no';
  }

  public createReactivationGid(): string {
    return `${GID_REACTIVATION_PREFIX}.${generateGUID()}`;
  }

  public isValid() {
    const hasGID = !!this.getGid();

    if (this.isNewSession()) {
      return hasGID && this.lead.hasRequiredFields();
    }
    return hasGID;
  }

  public setLastLeadGid(lastLeadGid: string): void {
    this.lastLeadGid = lastLeadGid;
  }

  public setLastLeadCreatedAt(lastLeadCreatedAt: number): void {
    this.lastLeadCreatedAt = lastLeadCreatedAt;
  }

  public getReactivationGidFromLocalStorage(): { gid: string; timestamp: number; usable: boolean } {
    const gid = this.localStorage.getItem(localStorageKey.REACTIVATION_GID);
    const timestamp = parseInt(this.localStorage.getItem(localStorageKey.REACTIVATION_GID_GENERATED_AT));
    return {
      gid,
      timestamp,
      usable: this.isGidUsable({ gid, timestamp })
    };
  }

  public getReactivationGidFromCps(): { gid: string; timestamp: number; usable: boolean } {
    const gid = this.lastLeadGid;
    const timestamp = this.lastLeadCreatedAt;
    return {
      gid,
      timestamp,
      usable: this.isGidUsable({ gid, timestamp })
    };
  }

  private isGidUsable({ gid, timestamp }: { gid: string; timestamp: number }): boolean {
    if (!this.isValidReactivationGid(gid)) {
      return false;
    }

    return this.isGidFresh(timestamp);
  }

  public isGidFresh(timestamp: number): boolean {
    return this.isTimestampFresh(timestamp);
  }

  public isReactivatedRecently(): boolean {
    const reactivationTime = parseInt(this.localStorage.getItem(localStorageKey.REACTIVATION_TIME));
    return this.isTimestampFresh(reactivationTime);
  }

  private isTimestampFresh(timestamp: number): boolean {
    if (isNaN(timestamp)) {
      return false;
    }

    return utils.isTimeWithinLimit(timestamp, this.reactivationGidValidity);
  }

  public selectGID(): string {
    const sessionGid = this.sessionStorage.getItem(sessionStorageKey.SESSION_GID);

    if (this.isValidGid(sessionGid)) {
      return sessionGid;
    }

    return this.getPreviousGidFromUrl() || this.getGidFromCookie() || this.createNewGid();
  }

  public getLeadId(): string {
    return this.leadId;
  }

  public getGid(): string {
    return this.gid;
  }

  public getVisitorId(): any {
    return this.visitorId;
  }

  public createNewGid(): string {
    return `${GID_PREFIX}.${generateGUID()}`;
  }

  public isNewSession() {
    return !this.getGidFromCookie();
  }

  public isValidLeadId(leadId: string): boolean {
    if (!leadId || typeof leadId !== 'string') {
      return false;
    }

    const match = leadId.match(/[A-Z]{3}-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/i) || [];
    return match.length > 0 && match[0] === leadId;
  }

  public getLeadIdFromCookie(): string {
    const leadId = Cookie.get(CookieKey.LEAD_ID);
    return this.isValidLeadId(leadId) ? leadId : '';
  }

  public getGidFromCookie(): string {
    const gid = Cookie.get(CookieKey.GID);
    return this.isValidGid(gid) ? gid : '';
  }

  public createLeadIdFromLocale(locale = 'de_DE'): string {
    const isoCode = countryMap[locale];
    return `${isoCode}-${generateGUID()}`;
  }

  private setInitialCookie() {
    const isLeadSaved = !!this.getGidFromCookie();
    const hasReferrerKey = Cookie.has(CookieKey.REFERRER);
    const hasLandingUrlKey = Cookie.has(CookieKey.LANDING_URL);
    const time = 0;

    if (!isLeadSaved) {
      if (!hasReferrerKey) {
        Cookie.set(CookieKey.REFERRER, document.referrer, time);
      }

      if (!hasLandingUrlKey) {
        Cookie.set(CookieKey.LANDING_URL, this.getUrl(), time);
      }
    }
  }

  public setGid(gid: string): void {
    this.gid = gid;
  }

  public getPreviousGidFromUrl(): string {
    const gid = getUrlParameter(urlParameter.LEAD_GID);
    const isValid = this.isValidGid(gid);

    if (!isValid) {
      return '';
    }

    return gid;
  }

  public getSessionGidFromUrl(): string {
    const gid = getUrlParameter(urlParameter.SESSION_GID);
    const isValid = this.isValidGid(gid);

    if (!isValid) {
      return null;
    }

    return gid;
  }

  public getCustomerProfileGidFromUrl(): string {
    const gid = getUrlParameter(urlParameter.SFMC_SUBSCRIBER_KEY);
    const isValid = this.isValidGid(gid);

    if (!isValid) {
      return '';
    }

    return gid;
  }

  public isValidGid(gid: string, prefix?: string): boolean {
    if (!gid || typeof gid !== 'string') {
      return false;
    }

    const regexpPrefix = prefix || '[\\d]{3,}';
    const reactivationGidRegExp = new RegExp(`^${regexpPrefix}\\.[\\w-:]+$`, 'i');
    return reactivationGidRegExp.test(gid);
  }

  private isValidReactivationGid(gid: string): boolean {
    return this.isValidGid(gid, GID_REACTIVATION_PREFIX);
  }

  public getUrl({ encode }: { encode: boolean } = { encode: false }): string {
    const url = window.location.href;

    return encode ? encodeURIComponent(url) : url;
  }

  public getLocale(): string {
    return this.locale;
  }
}

export default Session;
